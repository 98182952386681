<template>
  <section>
    <b-card no-body id="surgery-product-card" class="mb-2">
      <b-card-header class="header pt-3 pb-1" role="tab">
        <h5>Mat/med utilizados</h5>
        <b-button v-b-toggle.used-products-accordion variant="link">
          Ver menos
        </b-button>
      </b-card-header>
      <b-collapse id="used-products-accordion" visible accordion="used-products-accordion" role="tabpanel">
        <b-card-body class="pt-0">
          <hr class="mt-0"/>
          <b-container v-for="(procedure,index) in surgery.procedures" :key="index" class="m-0 p-0 procedures">
            <b-row class="procedure-container">
              <ItemIconHandler :type="procedure?.clinic_procedure?.type || procedure?.clinic_procedure_by_item?.type || procedure?.item?.type" class="mr-2"/>
              <span>
                {{ parseProcedureType(procedure) }} |
              </span>
              <h5 class="mb-0 ml-1">
                {{ procedure?.clinic_procedure?.name || procedure?.clinic_procedure_by_item?.name || procedure?.item?.name }}
              </h5>
            </b-row>
            <SearchSurgeryItems @select="item => addSurgeryItem(item, procedure.id)"/>
            <section class="table-container" v-if="items?.length || loading">
              <SurgeryItemsTable
                :items="items.filter(item => item?.surgery_information_procedure_id === procedure.id)"
                :loading="loading && addingItemToProcedure === procedure.id"
                @remove="removeItem"
                @moveToWarehouse="emitMoveToWarehouse"
                usedItems
              />
            </section>
            <div v-else class="d-flex justify-content-center text-align-center w-100">
              <p>Nenhum produto ou medicamento vinculado ao procedimento</p>
            </div>
          </b-container>
        </b-card-body>
        <b-card-footer class="footer">
          <b-button variant="link" v-b-modal.itemsOutputModal>
            Efetuar saída de produtos
          </b-button>
        </b-card-footer>
      </b-collapse>
    </b-card>
    <ItemsOutputModal
      :surgeryProcedures="surgery?.procedures"
      :patient="surgery?.appointment?.patient"
      :items="notTransactedItems"
      :addSurgeryItem="addSurgeryItem"
      @onSave="onTransaction"
    />
  </section>
</template>

<script>
import { parseProcedureType } from '@/utils/procedure-helper.js'
export default {
  name: 'UsedProducts',
  components: {
    ItemIconHandler: () => import('@/components/Overview/ItemIconHandler.vue'),
    SearchSurgeryItems: () => import('@/components/SurgeryCenter/Products/SearchSurgeryItems.vue'),
    SurgeryItemsTable: () => import('@/components/SurgeryCenter/Products/SurgeryItemsTable.vue'),
    ItemsOutputModal: () => import('@/components/SurgeryCenter/Products/Modal/ItemsOutputModal.vue'),
  },
  props: {
    surgery: {type: Object, required: true},
    items: {type: Array, default: () => []},
    loading: Boolean,
  },
  computed: {
    notTransactedItems: function () { return this.items.filter(
      item => !item?.transaction_id 
      && (item?.item_type.includes('Kit') || item?.item_type.includes('Product')) ) 
    }, 

  },
  data: () => ({
    surgeryItems: [],
    addingItemToProcedure: false,
  }),
  methods: {
    parseProcedureType(procedure) {
      const type = this.getSurgeryType(procedure)

      return parseProcedureType(type)
    },
    getSurgeryType(procedure) {
      return procedure?.clinic_procedure?.type || procedure?.clinic_procedure_by_item?.type || procedure?.item?.type
    },
    onTransaction () {
      this.$emit('onTransaction')
    },
    removeItem (id) {
      this.$emit('remove', id)
    },
    emitMoveToWarehouse (id) {
      this.$emit('moveToWarehouse', id)
    },
    openBillToReceiveProductModal(billToReceive) {
      this.billToReceive = billToReceive;
      this.$bvModal.show('bill-to-receive-product-modal');
    },
    async addSurgeryItem (item, surgeryProcedureId) {
      try {
        this.addingItemToProcedure = surgeryProcedureId
        await this.api.createSurgeryItem({
          surgery_information_id: this.surgery.id,
          item_id: item.id,
          item_type: item.type,
          surgery_information_procedure_id: surgeryProcedureId,
          situation: 'use'
        })
        this.$toast.success(`item adicionado com sucesso!`)
        this.$emit('update')
        this.item = null
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    getProcedureHealthPlanId(procedure) {
      const id = procedure?.clinic_procedure?.id || procedure?.clinic_procedure_by_item?.id || procedure?.item?.id
      const appointmentItem = this.surgery?.appointment?.appointment_items.find(
        item => item?.item_id === id
      )

      return appointmentItem?.health_plan_id
    },
  },
  watch: {
    items() {
      this.surgeryItems = this.items
    },
  }
}
</script>

<style lang="scss" src="./SurgeryItems.scss" />
